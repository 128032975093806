<style scoped>
</style>

<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="90px">
                    <el-form-item label="文件名：" prop="fileName">
                        <el-input v-model="m.fileName"></el-input>
                    </el-form-item>
                    <el-form-item label="文件扩展名：" prop="extend">
                        <el-input v-model="m.extend"></el-input>
                    </el-form-item>
                    <el-form-item label="文件存储路径：" prop="filePath">
                        <el-input v-model="m.filePath"></el-input>
                    </el-form-item>
                    <el-form-item label="文件业务类型：" prop="fileType">
                        <el-input v-model="m.fileType"></el-input>
                    </el-form-item>
                    <el-form-item label="文件大小：" prop="fileSize">
                        <el-input v-model="m.fileSize"></el-input>
                    </el-form-item>
                    <el-form-item label="相对路径：" prop="urlPath">
                        <el-input v-model="m.urlPath"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="mini" @click="ok('ruleForm')">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
//import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    //components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {},
            fileList: [],
        }
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data) {
                this.title = "修改 文件管理";
                this.m = data;
            } else {
                this.m = {
                    fileName: '',
                    extend: '',
                    filePath: '',
                    fileType: 0,
                    fileSize: 0,
                    urlPath: ''
                }
                this.title = "添加 文件管理";
            }
        },
        success(response, file, fileList) {
            console.log(response, file, fileList);
        },

        //提交文件管理信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.sa.post("/file/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {
    },
};
</script>